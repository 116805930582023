import React from "react";
import MainPage from "./components/MainPage"; 

function App() {
  return (
    <div className="App">
    <MainPage/> 
    </div>
  );
}

export default App;
