import React, { useState } from "react";
import UTMParameters from "./UTMParameters";
import Header from "../Common/Header";
import Googleads from "../images/googleads.png";
import Tabula from "../images/tabula.png";
import Youtube from "../images/youtube.png";
import Faceook from "../images/faceook.png";
import Tiktoc from "../images/tiktoc.png";

const optionsData = {
  YOUTUBE: {
    parameters: [
      "angle",
     // "ad_account_name",
      "creative",
     // "creative_id",
     // "offer_id",
      "gclid",
      "gbraid",
      "wbraid",
      "adgroupid",
      "campaign_id",
      "ad_id",
      "adset_id",
      "placement",
      "source",
      "cpid",
    ],
    sourceType: "YouTube",
  },
  "GOOGLE - SEARCH": {
    parameters: [
      "angle",
     // "ad_account_name",
     // "offer_id",
      "campaign_id",
      "adset_id",
      "ad_id",
      "placement",
      "source",
      "cpid",
      "gclid",
      "gbraid",
      "wbraid",
    ],
    sourceType: "GoogleSearch",
  },
  FACEBOOK: {
    parameters: [
      "angle",
     // "ad_account_name",
      "creative",
    //  "creative_id",
    //  "offer_id",
      "campaign_id",
      "adset_id",
      "ad_id",
      "placement",
      "source",
      "cpid",
      "fbclid",
    ],
    sourceType: "Facebook",
  },
  TABOOLA: {
    parameters: [
      "angle",
    //  "ad_account_name",
      "creative",
     // "creative_id",
     // "offer_id",
      "campaign_id",
      "placement",
      "source",
      "cpid",
      "click_id",
    ],
    sourceType: "Taboola",
  },
  TIKTOK: {
    parameters: [
      "angle",
     // "ad_account_name",
      "creative",
     // "creative_id",
     // "offer_id",
      "campaign_id",
      "adset_id",
      "ad_id",
      "placement",
      "source",
      "cpid",
      "ttclid",
    ],
    sourceType: "TikTok",
  },
};

const optionImages = {
  0: Youtube,
  1: Googleads,
  2: Faceook,
  3: Tabula,
  4: Tiktoc,
};

const MainPage = () => {
  const [link, setLink] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [linkDetails, setLinkDetails] = useState(null);
  const [error, setError] = useState("");
  const [error1,seterror1] = useState("");
  const [parameterStatus, setParameterStatus] = useState({}); 
  const [currantStep, SetcurrantStep] = useState(1);
 

  const handleLinkSubmit = () => {
    console.log(selectedOption);

    if(!selectedOption || selectedOption === "none") {
      seterror1("Please select traffic source");
      return;
    }
    else {
      seterror1("")
    }

    if (!link) {
      setError("Please enter a URL.");
      return;
    } 

    try {
      new URL(link);
    } catch (error) {
      setError("Please enter a valid URL.");
      return;
    }

    const urlObject = new URL(link);
    const domain = urlObject.hostname;
    const sourceType = domain.split(".")[1]?.toUpperCase() || "other";
    console.log("domain", domain);

    const parameters = optionsData[selectedOption]?.parameters || [];

    const presentParams = {};

    parameters.forEach((param) => {
      const value = urlObject.searchParams.get(param.split("=")[0]);
      presentParams[param] = value ? (
        value
      ) : ( 
        <> 
        <span className="text-danger fw-bold">Missing</span>    
        </>
      );
    });

    const { utmParams } = optionsData[selectedOption] || {};
    const url = link;

    setLinkDetails({ url, utmParams, sourceType });
    setParameterStatus(presentParams);
    SetcurrantStep(currantStep + 1);
    setError("");
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <div className="center_form">
              <div className="center_form_step">
                {currantStep === 1 && (
                  <>
                    <div className="step step-1">
                      <div className="row">
                        <div className="col-12">
                          <h2 className="mb-5 text-center">
                          UTM Validator
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        {Object.keys(optionsData).map((option, i) => (
                          <div className={`col-12 col-md-6 col-xl-4`} key={i}>
                            <label
                              className={`step_checkbox_inner ${
                                selectedOption === option ? "checked" : ""
                              }`}
                              for={`type_development-${i}`}
                            >
                              <input
                                name="type_development"
                                onChange={(e) => setSelectedOption(option)}
                                value={option}
                                type="radio"
                                id={`type_development-${i}`}
                              />
                              <span className="radio_overly"></span>
                              <img src={optionImages[i]} alt="website design" />
                              <strong>{option}</strong>
                            </label>
                          </div>
                        ))}


                        {error1 && (
                          <p className="text-danger mt-3">{error1}</p>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-12 mb-5">
                          <div className="col-auto">
                            <label className="form-label fw-bold mb-2">
                              URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                              placeholder="Enter the URL here"
                            />
                            {error && (
                              <p className="text-danger mt-3">{error}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                          <button
                        //    disabled={!selectedOption}
                            type="button"
                            onClick={handleLinkSubmit}
                            className="btn btn-primary w-100 btn-lg"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currantStep === 2 && (
                  <div className="step step-3">
                    {linkDetails && (
                      <div className="mt-3">
                        <h2 className="mb-2 text-center">UTM Breakdown</h2>
                        <p className="text-break link_details_para mt-4 d-inline-block">
                          <strong> URL: </strong> <br />
                          <span className="link_details_para_span">
                            {linkDetails.url}
                          </span>
                        </p>

                        <UTMParameters utmParams={linkDetails.utmParams} />
                        <div className="table-responsive mt-4">
                          <table className="table table-bordered table_data table-hover shadow-lg">
                            <thead className="bg-primary text-white">
                              <tr>
                                <th className="text-center">Name</th>
                                <th className="text-center">Parameter</th>
                                <th className="text-center">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="3" className="td_title">
                                  <b>Intenal Use</b>
                                </td>
                              </tr>
                              {/* Group: angle, ad_account_name, creative, creative_id, offer_id */}
                              {/*  <tr>
                             <td>
                            <b>Parameter</b>
                          </td>
                          <td colSpan="2"></td>
                          </tr> */}
                              {Object.entries(parameterStatus).map(
                                ([param, status]) => {
                                  const paramName = param
                                    .split("_")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ");
                                  if (
                                    [
                                      "angle",
                                      // "ad_account_name",
                                      "creative",
                                   //   "creative_id",
                                    //  "offer_id",
                                    ].includes(param)
                                  ) {
                                    return (
                                      <tr key={param}>
                                        <td>{paramName}</td>
                                        <td>{param}</td>   
                                        <td>    
                                        {status === 'update'  ?  <span className='not_available'> {status} -  Replace  {status}   with real value.   </span> : ( status.toString().charAt(0) === '{' && status.toString().charAt(status.toString().length - 1) === '}' || status.toString().charAt(0) === '_' && status.toString().charAt(status.toString().length - 1) === '_') ? <span className='not_available'> 
                                        {status} -  Replace  {status}   with real value. </span> : <span className='not_available active'> {status} </span> } </td>
                                         
                                      </tr>
                                    );
                                  }
                                  return null;
                                }
                              )}

                              {/* Group: Auto Generated Params */}
                              <tr>
                                <td colSpan="3" className="td_title">
                                  <b>Auto Generated Params</b>
                                </td>
                              </tr>
                              {Object.entries(parameterStatus).map(
                                ([param, status]) => {
                                  const paramName = param
                                    .split("_")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ");
                                  if (
                                    [
                                      "campaign_id",
                                      "adset_id",
                                      "ad_id",
                                      "placement",
                                      "source",
                                      "creative",
                                    ].includes(param)
                                  ) {
                                    return (
                                      <tr key={param}>
                                        <td>{paramName}</td>
                                        <td>{param}</td> 
                                        <td>   

 

                                        {status === 'update'  ?  <span className='not_available'> {status} - Replace  {status}   with real value. </span> : ( status.toString().charAt(0) === '{' && status.toString().charAt(status.toString().length - 1) === '}' || status.toString().charAt(0) === '_' && status.toString().charAt(status.toString().length - 1) === '_' ) ? <span className='not_available'> 
                                        {status} - Replace  {status}   with real value. </span> :   <span className='not_available active'> {status} </span>}   
                                        
                                        
                                        </td>
                                     
                                      </tr>
                                    );
                                  }
                                  return null;
                                }
                              )}

                              {/* Group: Voluum Camp id */}
                              <tr>
                                <td colSpan="3" className="td_title">
                                  <b>Voluum Camp id</b>
                                </td>
                              </tr>
                              {Object.entries(parameterStatus).map(
                                ([param, status]) => {
                                  if (param === "cpid") {
                                    return (
                                      <tr key={param}>
                                        <td>Campaign ID</td>
                                        <td>{param}</td>
                                        <td>  {status === 'update'  ?  <span className='not_available'> {status} - Replace  {status}   with real value. </span> : ( status.toString().charAt(0) === '{' && status.toString().charAt(status.toString().length - 1) === '}' || status.toString().charAt(0) === '_' && status.toString().charAt(status.toString().length - 1) === '_' ) ? <span className='not_available'> 
                                        {status} - Replace  {status}   with real value. </span> : <span className='not_available active'> {status} </span> } </td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                }
                              )}

                              {/* Group: Google Click Ids */}
                              {(selectedOption === "GOOGLE - SEARCH" ||
                                selectedOption === "YOUTUBE") && (
                                <tr>
                                  <td colSpan="3">
                                    <b>Google Click Ids</b>
                                  </td>
                                </tr>
                              )}
                              {(selectedOption === "GOOGLE - SEARCH" ||
                                selectedOption === "YOUTUBE") &&
                                ["gclid", "gbraid", "wbraid"].some(
                                  (param) => parameterStatus[param]
                                ) && (
                                  <>
                                    {parameterStatus["gclid"] && (
                                      <tr>
                                        <td>Gclid</td>
                                        <td>gclid</td>  
                                        <td>{parameterStatus["gclid"]}</td>
                                          </tr>
                                    )}
                                    {parameterStatus["gbraid"] && (
                                      <tr>
                                        <td>Gbraid</td>
                                        <td>gbraid</td>
                                        <td> {parameterStatus["gbraid"]} </td> 
                                      </tr>
                                    )}
                                    {parameterStatus["wbraid"] && (
                                      <tr>
                                        <td>Wbraid</td>
                                        <td>wbraid</td>
                                        <td>
                                        {parameterStatus["wbraid"]}   </td>
                                      </tr>
                                    )}
                                  </>
                                )}

                              {/* Group: Facebook Click Id */}
                              {selectedOption === "FACEBOOK" && (
                                <tr>
                                  <td>
                                    <b>Facebook Click Id</b>
                                  </td>
                                  <td colSpan="2"></td>
                                </tr>
                              )}
                              {selectedOption === "FACEBOOK" &&
                                parameterStatus["fbclid"] && (
                                  <tr>
                                    <td>FBC ID</td>
                                    <td>fbclid</td>  
                                    <td>  
                                        {parameterStatus["fbclid"] === 'update'  ?  <span className='not_available'> {parameterStatus["fbclid"]} - Replace  {parameterStatus["fbclid"]}   with real value. </span> : ( parameterStatus["fbclid"].toString().charAt(0) === '{' && parameterStatus["fbclid"].toString().charAt(parameterStatus["fbclid"].toString().length - 1) === '}' || parameterStatus["fbclid"].toString().charAt(0) === '_' && parameterStatus["fbclid"].toString().charAt(parameterStatus["fbclid"].toString().length - 1) === '_') ? <span className='not_available'> Replace  {parameterStatus["fbclid"]}   with real value. </span> : <span className='not_available active'> {parameterStatus["fbclid"]} </span> } 
                                     </td>
                                  </tr>
                                )}

                              {/* Group: Taboola Click Id */}
                              {selectedOption === "TABOOLA" && (
                                <tr>
                                  <td>
                                    <b>Taboola Click Id</b>
                                  </td>
                                  <td colSpan="2"></td>
                                </tr>
                              )}
                              {selectedOption === "TABOOLA" &&
                                parameterStatus["click_id"] && (
                                  <tr>
                                    <td>Click ID</td>
                                    <td>click_id</td>  
                                    <td>  
                                        {parameterStatus["click_id"] === 'update'  ?  <span className='not_available'> {parameterStatus["click_id"]} - Replace  {parameterStatus["click_id"]}   with real value. </span> : ( parameterStatus["click_id"].toString().charAt(0) === '{' && parameterStatus["click_id"].toString().charAt(parameterStatus["click_id"].toString().length - 1) === '}' || parameterStatus["click_id"].toString().charAt(0) === '_' && parameterStatus["click_id"].toString().charAt(parameterStatus["click_id"].toString().length - 1) === '_' )  ? <span className='not_available'> {parameterStatus["click_id"]} - Replace  {parameterStatus["click_id"]}   with real value. </span> : 
                                        <span className='not_available active'> {parameterStatus["click_id"]} </span> }   
                                     </td> 
                                  </tr>
                                )}

                              {/* Group: TikTok Click Id */}
                              {selectedOption === "TIKTOK" && (
                                <tr>
                                  <td className="td_title" colSpan="3">
                                    <b>TikTok Click Id</b>
                                  </td>
                                </tr>
                              )}
                              {selectedOption === "TIKTOK" &&
                                parameterStatus["ttclid"] && (
                                  <tr>
                                    <td>TTC ID</td>
                                    <td>ttclid</td>
                                    <td>  
                                        {parameterStatus["ttclid"] === 'update'  ?  <span className='not_available'> {parameterStatus["ttclid"]} - Replace  {parameterStatus["ttclid"]}   with real value. </span> : ( parameterStatus["ttclid"].toString().charAt(0) === '{' && parameterStatus["ttclid"].toString().charAt(parameterStatus["ttclid"].toString().length - 1) === '}' || parameterStatus["ttclid"].toString().charAt(0) === '_' && parameterStatus["ttclid"].toString().charAt(parameterStatus["ttclid"].toString().length - 1) === '_' )  ? <span className='not_available'> 
                                        {parameterStatus["ttclid"]} - Replace  {parameterStatus["ttclid"]}   with real value. </span> : 
                                        <span className='not_available active'>  {parameterStatus["ttclid"]} </span> } 
                                     </td> 
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>  
    </>
  );
};

export default MainPage;
