import React, { useEffect, useState } from "react";
import Logo from '../images/logo.png';
import Calender from '../images/calendar.png';

const Header = () => { 

    const [Todaydate,setTodaydate] = useState('');
    const [currentTime, setCurrentTime] = useState("");

const currentDate = new Date(); 
const monthNames = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"]; 
const day = currentDate.getDate();
const monthIndex = currentDate.getMonth();  
const monthName = monthNames[monthIndex]; 
const formattedDate = monthName + ' ' + day ; 


const intervalId = setInterval(() => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // Handle midnight (0 hours)
    setCurrentTime(`${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${ampm}`);
  }, 1000);  

useEffect(() => {
    setTodaydate(formattedDate);  
      return () => clearInterval(intervalId);
},[])

    
    return(
        <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark  p-md-4 p-3">
            <div className="container-fluid">
            <div className="row w-100">
            
                    <div className="col-12 col-md-12 d-flex justify-content-center justify-content-md-center">
                        <a className="navbar-brand" href="/">
                                <img src={Logo} alt=""   />
                        </a> 
                    </div>

                    <div className="col-12 col-md-12 d-none  d-md-flex mt-4 mt-md-0 justify-content-center justify-content-md-around">
                        <div className="header_details w-100">
                                <div className="header_details_inner d-flex justify-content-md-between">
                                    <div className="header_details_user">
                                        <strong> ADSYNC </strong>
                                        <span> Hello Patrice, Welcome Back </span>
                                    </div>
                                    <div className="header_details_date">

                                    <div className="header_details_date_left">
                                    <img src={Calender} />
                                    </div>
                                       
                                        <div className="header_details_date_right">
                                                <strong> {Todaydate} </strong> 
                                                <span>{currentTime}</span>
                                        </div>
                                   
                                </div>
                                </div>    
                        
                        </div>
                    </div>
                    </div>
            </div>
        </nav>
        </>
    )
}

export default Header;