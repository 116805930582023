import React from "react";

const UTMParameters = ({ utmParams }) => {
  if (!utmParams || Object.keys(utmParams).length === 0) {
    return null; // Return null if utmParams is undefined, null, or an empty object
  }

  return (
    <div>
      <h5>UTM Parameters:</h5>
      <ul>
        {Object.entries(utmParams).map(([key, value]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UTMParameters;
